<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(action == 'insert')">{{ $t("FAQ-CATEGORY.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(action == 'update')">{{ $t("FAQ-CATEGORY.EDIT-ITEM") }}</MDBCardHeader>
        <MDBCardText class="pt-3">
          <MDBRow class="g-3">
            <MDBCol md="6">
              <MDBRow class="g-3">
                <template v-for="(name, lang) in Form.name.value" :key="lang">
                  <div>
                    <label class="form-label text-dark">{{ ($t(`LANG.${lang}`)+$t(`COMMON.NAME`)) }}<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="name" :placeholder="($t(`LANG.${lang}`)+$t(`COMMON.NAME`))" v-model="Form.name.value[lang].name">
                    <span class="text-danger">{{ errors[`name.${lang}.name`] }}</span>
                  </div>
                </template>
                <MDBCol md="12">
                  <div>
                    <label class="form-label text-dark">{{ $t(`COMMON.SORT`) }}<span class="text-danger">*</span></label>
                    <input type="number" class="form-control" name="name" :placeholder="$t(`COMMON.SORT`)" v-model="Form.sort.value">
                    <span class="text-danger">{{ sortError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardText>
        <MDBBtn v-if="(action == 'insert')" color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn v-else-if="(action == 'update')" color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.UPDATE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close()">{{ $t("COMMON.CANCEL") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBBtn, MDBCol } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service";

export default {
  name: "FaqCategoryForm",
  components: {
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
    MDBCol
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });
    const GetTable = inject("GetTable");
    const SubmitLoading = ref(false);
    const i18n = useI18n();
    var mutilpleLangsNmaeValue = {};
    const mutilpleLangsNmae = {};
    for(let k in i18nService.languages) {
      mutilpleLangsNmae[i18nService.languages[k].lang] = yup.object(({ name: yup.string().required().label((i18n.t(`LANG.${i18nService.languages[k].lang}`)+i18n.t(`COMMON.NAME`)))}));
      mutilpleLangsNmaeValue[i18nService.languages[k].lang] = { name: ""} ;
    }

    const basicSchema = {
      name: yup.object(mutilpleLangsNmae).required().label(i18n.t("COMMON.NAME")),
      sort: yup.number().required().label(i18n.t("COMMON.SORT")),
    };

    let ExtendSchema = {};
    if(props.action == "insert") {
      ExtendSchema = {
      };  
    }else{
      ExtendSchema = {
      };
    }
    
    const MemberSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm, errors } = useForm({
      validationSchema: MemberSchema,
      initialValues: {
        name: {...mutilpleLangsNmaeValue},
        sort: 0,
      }
    });

    const { value: name, errorMessage: nameError } = useField('name');
    const { value: sort, errorMessage: sortError } = useField('sort');

    for(let k in i18nService.languages) {
      useField(`name.${i18nService.languages[k].lang}.name`);
    }

    let Submit;

    if(props.action == "insert") {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/FaqCategory", values).then(response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            SubmitLoading.value = false;
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/FaqCategory",props.uuid ,values).then(response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            SubmitLoading.value = false;
            FormUUid.value = "";
            GetTable();
          }
        });
      });
    }

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

    return {
      Model,
      SubmitLoading,
      Form: {
        name,
        sort
      },
      nameError,
      sortError,
      Submit,
      FormUUid,
      resetForm,
      Close,
      errors,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    }
  },
  methods: {
    GetDetail() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/FaqCategory", this.uuid).then(response => {
            if (response.status == 200) {
              this.resetForm();
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "name":
                      for(let lang in response.data.data.name){
                        this.Form.name.value[lang].name = response.data.data.name[lang].name;
                      }
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    }
  }
};
</script>