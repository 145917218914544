<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol class="mb-2 text-start" md="2">
            <MDBSelect v-model:options="language.items" :label="$t('COMMON.LANGUAGE')" v-model:selected="lang"></MDBSelect>
          </MDBCol>
          <!-- <MDBCol md="2">
            <MDBInput :label="$t('COMMON.NAME')" :placeholder="$t('COMMON.NAME')" v-model="where.name" />
          </MDBCol> -->
          <MDBCol class="mb-2 text-end">
            <!-- <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn> -->
            <MDBBtn color="info" @click="add_dialog = true">{{ $t("FAQ-CATEGORY.ADD-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <template v-slot:name="{ item }">
            {{ item.name[lang].name }}
          </template>
          <template v-slot:faq="{ item }">
            {{ item.faq.length }}
          </template>
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <!-- 操作template -->
          <!-- <template v-slot:uuid="{ item }">
            <a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('FAQ-CATEGORY.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
            <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('FAQ-CATEGORY.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template> -->
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <template v-slot:uuid="{ item }">
            <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
	<FaqCategoryForm action="insert" v-model="add_dialog"></FaqCategoryForm>
	<FaqCategoryForm action="update" v-model="edit_dialog" v-model:uuid="EditUUid"></FaqCategoryForm>
</template>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBSelect } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import FaqCategoryForm from '@/components/Form/Faq/FaqCategoryForm';
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import CommonService from "@/core/services/common.service";
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "FaqCategory",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		FaqCategoryForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBSelect,
  },
  setup() {
    const i18n = useI18n();
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const EditUUid = ref("");
    const total = ref(0);
    const lang = ref(i18nService.getActiveLanguage());
    const language = reactive({
      items: i18nService.languages.map(item => { item.selected = (item.lang == i18nService.getActiveLanguage()); return item;}),
      selected: ""
    });
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["sort"],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const where = reactive({
      name: "",
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",class: "text-center",width:"50px"
      },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.QUANTITY"), value: "faq" },
      { text: i18n.t("COMMON.SORT"), value: "sort" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,class: "text-center",width:"50px"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,class: "text-center",width:"50px"}
    ]);
    const desserts = ref([]);
    const Common = CommonService;

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/FaqCategory",
          {
            params: Object.assign(CommonService.SortHandleObject(options),where)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/FaqCategory/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

		return {
      add_dialog,
      edit_dialog,
      loading,
      EditUUid,
      options,
      headers,
      total,
      desserts,
      Common,
      GetTable,
      DeleteAction,
      lang,
      language,
      where,
    };
  },
  mounted() {
    this.GetTable();
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  }
};
</script>